.welcome-banner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.welcome-banner-header {
    font-size: 6vw;
    font-weight: bold;
}

.welcome-banner-subheading {
    font-size: 3vw;
    font-weight: lighter;
}

@media (min-width: 1200px) {  
    .welcome-banner-header {
        font-size: 6em;
        font-weight: bold;
    }
    
    .welcome-banner-subheading {
        font-size: 3em;
        font-weight: lighter;
    }
}  

.stockings-logo {
    max-height: 25vw;
    max-width: 25vw;
    animation: rotation 2s infinite alternate;
}

@keyframes rotation {
    from {
        transform: rotate(-5deg);
    }
    to {
        transform: rotate(25deg);
    }
}



.google-g {
    height: 1em;
    margin-right: .5em;
}