@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

html, #root {
  background-color: #333 !important;
  color: #fff !important;
  font-family: 'Open Sans', sans-serif !important;
}

body {
  margin: 0;
}

.bg-green {
  background-color: #3AAFA9 !important;
}

.bg-grey {
  background-color: #333 !important;
}

.bg-light-grey {
  background-color: #404040 !important;
}

.font-green {
  color: #3AAFA9 !important;
}

.stockings-btn {
  border: 1px solid #333 !important;
  color: #fff !important;
  background-color: #3AAFA9 !important;
}
.stockings-btn:hover {
  background-color: #0A7F79 !important;
}

.stockings-btn-grey {
  border: 1px solid #fff !important;
  color: #fff !important;
  background-color: #333 !important;
}
.stockings-btn-grey:hover {
  background-color: #444 !important;
}

.span-height {
  min-height: 100vh;
}

.span-width {
  min-width: 100vw;;
}

.mw-100 {
  max-width: 100px;
}

.mw-200 {
  max-width: 200px;
}

.mw-300 {
  max-width: 300px;
}

.mw-400 {
  max-width: 400px;
}
